import React from "react"
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"

const Title = styled.h1`
  margin-top: 3rem;
  text-align: center;
`;

const Subtitle = styled.div`
  text-align: center;
`;

const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Home"/>
    <Title> davidp.io </Title>
    <Subtitle> Under construction </Subtitle>
  </Layout>
)

export default IndexPage
